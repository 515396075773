<template>
  <div class="wrap">
    <div class="banner">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">关于我们</p>
      </div>
    </div>
    <div class="content bg"
         id="a1">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>公司<em>介绍</em></h2>
        <div class="details">
          <div class="img">
            <div class="p1"></div>
            <div class="p2"><img src="../../assets/images/about.png" /></div>
          </div>
          <div class="text">
            <p>英特沃德（武汉）医疗高科，是集医疗系统开发、医疗大数据应用、软件系统研发与集成、医疗装备研发生产与集成、投资、销售与运营服务为一体的高科技企业，项目坐落在中国光谷。英特沃德正借助国家鼓励科技创新和中国智造的划时代机遇，运用创新科技，集成AI+互联网+大数据+金融+物联网+大医疗+大健康概念，全力打造自身成为创新与领先的集成医疗大数据+医疗装备智造的临床支持系统供应商。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content"
         id="a2">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>企业<em>发展</em></h2>
        <div class="step">
          <div class="con">
            <div class="item"
                 style="margin-bottom: 188px">
              <div class="num">
                <span>2014</span>
              </div>
              <div class="txt right">CDR数字病区概念及系统架构由北美大学健康联盟NUH在其所属的一系列北美地区学术带头医院率先采用并不断完善。</div>
            </div>
            <div class="item">
              <div class="num">
                <span>2017</span>
              </div>
              <div class="txt">2014-2017年开始由中加政府医疗合作项目引进中国，并结合中国国情进行了全新深入开发。</div>
            </div>
            <div class="item">
              <div class="num">
                <span>2018</span>
              </div>
              <div class="txt right">2018年武汉英特沃德成立，正式将CDR数字病区推向国内高端学术带头医院！</div>
            </div>
            <div class="item">
              <div class="num">
                <span>2019</span>
              </div>
              <div class="txt">2019年第七届世界军人运动会官方指定医院（中国人民解放军中部战区总医院）后勤保障部项目军运会住院专区(CDR数字病区系统)落地运行。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content bg"
         id="a3">
      <div class="en t5"></div>
      <div class="inner product">
        <h2>企业<em>资质</em></h2>
        <div class="details2">
          <!-- <div class="text">
            <p>英特沃德（武汉）医疗高科，是集医疗系统开发、医疗大数据应用、软件系统研发与集成、医疗装备研发生产与集成、投资、销售与运营服务为一体的高科技企业，项目坐落在中国光谷。英特沃德正借助国家鼓励科技创新和中国智造的划时代机遇，运用创新科技，集成AI+互联网+大数据+金融+物联网+大医疗+大健康概念，全力打造自身成为创新与领先的集成医疗大数据+医疗装备智造的临床支持系统供应商。</p>
          </div> -->
          <div class="tabs">
            <a v-for="(item, index) in zsTitle"
               :class="{'active': zsActive === index}"
               @click="chenge(index)"
               href="javascript:;"
               :key="index">{{ item }}</a>
          </div>
          <div class="list"
               v-show="zsActive === 2">
            <ul>
              <li style="width: 374px;height: 254px;"><img src="../../assets/images/zizhi/qy-3.png" /></li>
              <li style="width: 374px;height: 254px;"><img src="../../assets/images/zizhi/qy-4.png" /></li>
              <li style="width: 374px;height: 254px;"><img src="../../assets/images/zizhi/qy-5.png" /></li>
            </ul>
          </div>
          <div class="list"
               v-show="zsActive === 0">
            <ul>
              <!-- <li><img src="../../assets/images/zizhi/1.png" /></li> -->
              <li><img src="../../assets/images/zizhi/2.png" /></li>
              <li><img src="../../assets/images/zizhi/3.png" /></li>
              <li><img src="../../assets/images/zizhi/4.png" /></li>
              <li><img src="../../assets/images/zizhi/5.png" /></li>
              <li><img src="../../assets/images/zizhi/6.png" /></li>
              <li><img src="../../assets/images/zizhi/7.png" /></li>
              <li><img src="../../assets/images/zizhi/8.png" /></li>
              <li><img src="../../assets/images/zizhi/9.png" /></li>
              <li><img src="../../assets/images/zizhi/10.png" /></li>
              <li><img src="../../assets/images/zizhi/11.png" /></li>
              <li><img src="../../assets/images/zizhi/12.png" /></li>
              <li><img src="../../assets/images/zizhi/13.png" /></li>
            </ul>
          </div>
          <div class="list rj"
               v-show="zsActive === 1">
            <ul>
              <li><img src="../../assets/images/zizhi/rj-1.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-2.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-3.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-4.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-5.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-6.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-7.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-8.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-9.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-10.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-11.png" /></li>
              <li><img src="../../assets/images/zizhi/rj-12.png" /></li>
              <!-- <li><img src="../../assets/images/zizhi/rj-13.png" /></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="content"
         id="a4">
      <div class="en t4"></div>
      <div class="inner product">
        <h2>联系<em>我们</em></h2>
        <div class="details">
          <div class="img">
            <div class="p1"></div>
            <div class="p2"><img src="../../assets/images/about2.jpg" /></div>
          </div>
          <div class="text">
            <div class="site">
              <div class="dl"><span class="dt address">地址：</span><span class="dd">武汉市东湖高新技术开发区花城大道8号武汉软件新城2期C11栋7层</span></div>
              <div class="dl"><span class="dt email">邮箱：</span><span class="dd">ytwd@ytwdwh.com</span></div>
              <div class="dl"><span class="dt tel">电话：</span><span class="dd">027-65527829</span></div>
              <div class="code">
                <img src="../../assets/images/code.png"
                     alt="">
                <span>关注微信</span>
              </div>
            </div>
          </div>
        </div>
        <div class="map">
          <img src="../../assets/images/map.png"
               alt="">
        </div>
      </div>
    </div>
    <div class="tab fixed"
         v-show="isFixed">
      <div class="inner">
        <a href="javascript:;"
           :class="{active:index === isActive}"
           v-for="(item, index) in jobTitle"
           :key="index"
           @click="goAnchor('#a' + (index + 1), index)">{{ item }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
  metaInfo: {
    title: '英特沃德（武汉）医疗科技发展有限公司-关于我们', // set a title
    meta: [{ // set meta
      name: '医疗系统开发，医疗大数据应用，软件系统研发与集成，医疗装备研发生产与集成，数字病区',
      content: '英特沃德（武汉）医疗高科，是集医疗系统开发、医疗大数据应用、软件系统研发与集成、医疗装备研发生产与集成、投资、销售与运营服务为一体的高科技企业，项目坐落在中国光谷。'
    }],
    link: [{ // set link
      rel: '英特沃德（武汉）医疗科技发展有限公司',
      href: 'http://www.ytwdwh.com/'
    }]
  },
  props: {
    scrollTopNum: Number
  },
  data () {
    return {
      isActive: 0,
      jobTitle: ['公司介绍', '企业发展', '企业资质', '联系我们'],
      zsTitle: ['软件著作证书', '软件产品证书', '企业资质证书'],
      zsActive: 0,
      isFixed: false,
      pageTop: {
        a1: 0,
        a2: 0,
        a3: 0,
        a4: 0
      }
    }
  },
  mounted () {
    this.pageTop.a1 = this.$el.querySelector('#a1').offsetTop
    this.pageTop.a2 = this.$el.querySelector('#a2').offsetTop
    this.pageTop.a3 = this.$el.querySelector('#a3').offsetTop
    this.pageTop.a4 = this.$el.querySelector('#a4').offsetTop
  },
  watch: {
    scrollTopNum (val) {
      // val > 450 ? this.isFixed = true : this.isFixed = false
      // 距离顶部距离设置为固定
      val > this.pageTop.a1 ? this.isFixed = true : this.isFixed = false
      // 根据顶部距离定位锚点
      if (val > this.pageTop.a1 && val < this.pageTop.a2) {
        this.isActive = 0
      } else if (val > this.pageTop.a2 && val < this.pageTop.a3) {
        this.isActive = 1
      } else if (val > this.pageTop.a3 && val < this.pageTop.a4) {
        this.isActive = 2
      } else if (val > this.pageTop.a4) {
        this.isActive = 3
      }
    }
  },
  methods: {
    goAnchor (selector, index) {
      // this.isActive = index
      this.$el.querySelector(selector).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start' // 上边框与视窗顶部平齐, 默认值
      })
    },
    chenge (index) {
      this.zsActive = index
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 444px;
  margin-top: 76px;
  background: url('../../assets/images/banner4.png') no-repeat center;
  text-align: center;
  overflow: hidden;
  .text {
    display: inline-block;
    margin: 117px auto 0;
  }
  .icon {
    width: 105px;
    height: 105px;
    margin: 0 auto;
    background: url('../../assets/images/banner4-icon.png') no-repeat center;
  }
  .h2 {
    font-size: 30px;
    color: #fff;
    margin-top: 62px;
  }
}
.bg {
  background: #fafafa;
}
.content {
  position: relative;
  padding: 80px 0 55px;
  .en {
    width: 100%;
    height: 69px;
    text-align: center;
    border-bottom: 1px solid #f3f3f3;
    position: absolute;
    top: 80px;
    left: 0;
    background-position: center top;
    background-repeat: no-repeat;
    &.t1 {
      background-image: url('../../assets/images/Company-introduction.png');
    }
    &.t2 {
      background-image: url('../../assets/images/Enterprise-development.png');
    }
    &.t3 {
      background-image: url('../../assets/images/Corporate-honor.png'); // 企业荣誉
    }
    &.t4 {
      background-image: url('../../assets/images/Contact-us.png');
    }
    &.t5 {
      background-image: url('../../assets/images/Contact-zizhi.png');
    }
  }
  .product {
    position: relative;
    z-index: 1;
    h2 {
      width: 338px;
      height: 68px;
      line-height: 68px;
      color: #6a6a6a;
      font-size: 24px;
      border-bottom: 2px solid #00dcd4;
      text-align: center;
      margin: 0 auto;
      em {
        color: #00dcd4;
      }
    }
    .details {
      display: flex;
      justify-content: space-between;
      margin: 80px 0;
      .img {
        width: 558px;
        min-height: 314px;
        position: relative;
        // background: #DDF6F5;
        // border-radius: 10px;
        // margin-left: 15px;
        .p1 {
          width: 558px;
          height: 262px;
          margin-top: 26px;
          background: #d6f8f7;
        }
        .p2 {
          width: 468px;
          height: 314px;
          margin: 0 auto;
          position: absolute;
          top: 0;
          left: 45px;
          img {
            width: 100%;
          }
        }
      }
      .text {
        width: 598px;
        margin-top: 49px;
        // padding: 26px 30px;
        // margin-right: 30px;
        // box-shadow:1px 1px 24px 0px rgba(0, 0, 0, 0.14);
        // border-radius: 40px;
        position: relative;
        p {
          text-indent: 2em;
          font-size: 18px;
          line-height: 36px;
          color: #6a6a6a;
        }
        .site {
          width: 365px;
          margin-left: 35px;
          line-height: 28px;
          .dl {
            display: flex;
            margin-top: 18px;
            .dt {
              width: 75px;
              &::before {
                content: '';
                display: block;
                float: left;
                width: 22px;
                height: 22px;
                margin-top: 3px;
                margin-right: 5px;
                background-position: center;
                background-repeat: no-repeat;
              }
              &.address::before {
                background-image: url('../../assets/images/address2.png');
              }
              &.tel::before {
                background-image: url('../../assets/images/tel2.png');
              }
              &.email::before {
                background-image: url('../../assets/images/email2.png');
              }
            }
            .dd {
              flex: 1;
            }
          }
          .code {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 80px;
            left: 270px;
            span {
              display: block;
              text-align: center;
              margin-top: -12px;
            }
          }
        }
      }
    }
    .details2 {
      margin-top: 30px;
      .text {
        padding: 0 55px;
        p {
          text-indent: 2em;
          font-size: 18px;
          line-height: 36px;
          color: #6a6a6a;
        }
      }
      .list {
        margin: 30px auto 0;
        overflow: hidden;
        ul {
          width: 1500px;
        }
        li {
          width: 280px;
          height: 380px;
          float: left;
          margin-right: 26px;
          margin-bottom: 26px;
          background: #00dcd4;
        }
        &.rj {
          ul {
            width: 1300px;
            padding-left: 25px;
          }
          li {
            // width: 292px;
            // height: 212px;
            width: 350px;
            height: 254px;
            float: left;
            margin-right: 50px;
            margin-bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .step {
      margin: 80px 0;
      .con {
        position: relative;
      }
      .con::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 90%;
        background: #00dcd4;
        left: 50%;
        top: 10px;
        margin-left: -2px;
        z-index: 1;
      }
      .item {
        position: relative;
        z-index: 2;
        margin-bottom: 40px;
        height: 100px;
        .num {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          border: 1px solid #00dcd4;
          border-radius: 50%;
          span {
            display: inline-block;
            text-align: center;
            width: 84px;
            height: 84px;
            margin: 7px;
            background: #00dcd4;
            border-radius: 50%;
            font-size: 30px;
            font-weight: bold;
            color: #fff;
            line-height: 84px;
          }
        }
        .txt {
          width: 480px;
          padding: 40px 35px;
          font-size: 16px;
          line-height: 30px;
          border-radius: 25px;
          color: #6a6a6a;
          background: #fff;
          box-shadow: 1px 1px 9px 0px rgba(0, 0, 0, 0.14);
          position: absolute;
          top: -20px;
          left: 0;
          &.right {
            left: auto;
            right: 0;
          }
          &.right:before {
            right: auto;
            left: -34px;
            transform: rotate(0deg);
          }
          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 34px;
            height: 29px;
            background: url('../../assets/images/arrow.png') no-repeat center;
            top: 50%;
            margin-top: -14px;
            right: -34px;
            transform: rotate(180deg);
          }
          // &:before,
          // &:after {
          //   position:absolute;
          //   content:'';
          //   width:14px;
          //   height:14px;
          //   bottom:-8px;
          //   left:50%;
          //   margin-left:-7px;
          //   overflow:hidden;
          //   pointer-events:none;
          //   transform:rotate(45deg);
          // }
          // &:before {
          //   // background:red;
          //   box-shadow:1px 1px 2px rgba(0, 0, 0, 0.05);
          // }
          // &:after{
          //   bottom:-7px;
          //   background:#fff;
          // }
        }
      }
    }
    .map img {
      display: block;
      margin: 0 auto;
      padding: 30px;
      background: #fafafc;
      border-radius: 10px;
    }
  }
}
.tab {
  background: #fafafa;
  width: 100%;
  height: 100px;
  padding: 30px 0;
  position: absolute;
  top: 0;
  right: 10px;
  &.fixed {
    position: fixed;
    z-index: 10;
    width: 150px;
    // top: 76px;
    top: 100px;
    // left: 50%;
    // margin-left: 450px;
    background: none;
    .inner {
      width: 100%;
    }
    a {
      margin-left: 0;
      margin-bottom: 20px;
    }
  }
  .inner {
    width: 1200px;
    margin: 0 auto;
  }
  a {
    font-size: 16px;
    color: #00dcd4;
    background-color: #fff;
    width: 150px;
    line-height: 38px;
    border: 1px solid #00dcd4;
    text-align: center;
    border-radius: 20px;
    margin-left: 133px;
    display: inline-block;
    // &:hover
    &.active {
      background: #00dcd4;
      color: #fff;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
.tabs {
  a {
    font-size: 16px;
    color: #00dcd4;
    background-color: #fff;
    width: 150px;
    line-height: 38px;
    border: 1px solid #00dcd4;
    text-align: center;
    border-radius: 20px;
    margin-left: 133px;
    display: inline-block;
    // &:hover
    &.active {
      background: #00dcd4;
      color: #fff;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
